<template>
  <div>
    <h2 class="h2">Vocabulary in this game</h2>
    <span>The number of words to add for each lesson: 15</span>
    <word-list :word-ids="componentData.content.words" :milestone-id="componentData.content.milestone" v-bind="$attrs" :statusFilter="1" />
  </div>
</template>

<script>
import WordList from '../../../words/views/_components/WordList.vue'
export default {
  components: {
    WordList,
  },
  props: {
    componentData: {type: Object, required: true},
  },
};
</script>
