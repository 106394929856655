<template>
  <div class="page-content" v-if="lesson">
    <page-breadcrumb class="mb-3"
      title="Lesson edit"
      :items="breadcrumbItems"
    />
    <section>
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <form-generator :model="lesson" :schema="item_form_schema" />
      </b-card>
    </section>
    <section class="mb-3">
      <type-detail :component-data="lesson" />
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteStage"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import TypeDetail from './_components/TypeDetail.vue'
import service from '../service'
import topicService from '../../topic/service'
import { removeEmpty, safeGet, buildSelectOptions } from '@core/utils/index';
const topic_options = [];
const type_string = [];
const item_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', input_type: 'ui-component' },
      { label: 'Type', field: 'type',  input_type: 'select', options: type_string, disabled: true},
      // { label: 'Published ?', field: 'is_published', input_type: 'switch'},
      { label: 'Select Topic', field: 'topic_id', input_type: 'select', options: topic_options },
      // { label: 'Display order', field: 'display_order', disabled: true },
    ]
  }
];
export default {
  components:{
    TypeDetail,
  },
  data(){
    return {
      lesson: null,
      isUpdating: false,
      isDeleting: false,
      item_form_schema,
    }
  },
  computed: {
    lesson_id() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Learning Paths',
          to: { name: 'learning_path-list' },
        },
        {
          text: `Level ${this.lesson.english_level}`,
          to: { name: 'learning_path-detail', params: { id: this.lesson.path_id } },
        },
        {
          text: `Lesson ${this.lesson.display_order + 1}`, active: true
        },
      ];
      return items;
    },
  },
  created(){
    this.getLesson();
    this.getTopics();
  },
  methods:{
    async getLesson() {
      let response = await service.get({ id: this.lesson_id });
      if (response) {
        this.lesson = response.document;
        let opts = safeGet(() => buildSelectOptions(response.meta['LESSON_TYPE_STRING'])) || [];
        type_string.length = 0;
        type_string.push(...opts);
        // type_string = response.meta['LESSON_TYPE_STRING']
      }
    },
    async update() {
      this.isUpdating = true;
      const response = await service.update({
        data: JSON.stringify(this.lesson),
      });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully updated lesson!",
        });
      }
      this.getLesson();
      this.isUpdating = false;
    },
    async deleteStage() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.lesson_id });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully deleted lesson!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "learning_lesson-list" });
    },
    async getTopics() {
			let list = await topicService.getAll();
			let options = list.map((item) => { return { text: item.name, value: item._id } });
			topic_options.push(...options);
		},
  }
}
</script>
