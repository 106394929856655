<template>
  <div>
    <b-modal
      id="modal-primary modal-lg"
      ok-only
      ok-title="Save"
      modal-class="modal-primary"
      centered
      :title="question.title"
      v-model="isOpen"
      size="lg"
      @ok="save"
      @close="close"
      @hidden="close"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Time (s)" label-for="h-time" label-cols-md="3">
            <b-form-input
              v-model="question.time"
              type="number"
              id="h-time"
              placeholder="Time"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Title"
            label-for="h-title"
            label-cols-md="3"
          >
            <b-form-textarea
              v-model="question.title"
              id="textarea-title"
              placeholder="Title"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Question content"
            label-for="h-content"
            label-cols-md="3"
          >
            <b-form-textarea
              v-model="question.content"
              id="textarea-content"
              placeholder="Content"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="type_question == 2">
          <b-form-group
            label="Question"
            label-for="h-question"
            label-cols-md="3"
          >
            <input-tag
              placeholder="Add Word"
              :list-tag="question.questions"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group label="Answer" label-for="h-nswer" label-cols-md="3">
            <input-tag
              placeholder="Add Word"
              :list-tag="question.answers"
              class="mb-2"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-else>
          <b-form-group label="Answer" label-for="h-number" label-cols-md="3">
            <b-row
              v-for="(item, index) in question.questions"
              :key="index"
              style="border-radius: 5px"
              class="border p-1 mb-1"
            >
              <b-col style="margin-top: 6%" cols="1">
                <b-form-radio
                  v-model="single_selected"
                  name="answer"
                  :value="item.value"
                  :disabled="item.value ? false : true"
                />
              </b-col>
              <b-col cols="10">
                <b-form-textarea
                  v-model="item.value"
                  id="textarea-answer"
                  rows="3"
                >
                  {{ item.value }}
                </b-form-textarea>
              </b-col>
              <b-col cols="1" style="margin-top: 5%">
                <b-button
                  @click="deleteAnswerSingle(index, item.value)"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <div class="text-center mt-1">
              <b-button size="sm" variant="primary">
                <span class="align-middle" @click="addAnswerSingle">
                  Add Answer
                </span>
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="Correct answer translation"
            label-for="h-correctTrans"
            label-cols-md="3"
          >
            <ui-component :component-data="question.correct_answer_trans" class="border" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Answer explain"
            label-for="h-explain"
            label-cols-md="3"
          >
            <ui-component :component-data="question.answer_explain" class="border" />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import defaultConstant from "./defaultConstant";
import InputTag from "./InputTag.vue";
import defaultObject from "../defaultObject"
export default {
  components: {
    InputTag,
  },
  props: {
    questions: { type: Array, required: true },
    type_question: { type: Number, default: 0 },
  },
  data() {
    return {
      isOpen: false,
      single_selected: null,
      question: JSON.parse(JSON.stringify(defaultObject)),
      questionUpdate: null,
    };
  },
  watch: {
    single_selected: function (newValue) {
      this.question.answers = [newValue.trim()];
    },
    type_question: function (newValue, oldValue) {
      if (this.questions[this.questionUpdate]) {
        this.questions[this.questionUpdate].type = newValue;
      }
      if (oldValue && newValue) {
        this.question = { ...this.question, answers: [], questions: [] };
      }
    },
    questionUpdate: function (newValue) {
      if (this.questions[newValue]) {
        this.questions[newValue].type = this.type_question;
      }
    },
  },
  methods: {
    openModal(questionUpdate = null) {
      if (questionUpdate || questionUpdate == 0) {
        this.questionUpdate = questionUpdate;
        this.question = this.questions[questionUpdate];
        if (this.type_question == defaultConstant.SINGLE_CHOICE) {
          this.single_selected = this.questions[questionUpdate]?.answers[0];
          this.convertQuestionSingle();
        }
      } else {
        this.resetQuestion();
      }
      this.isOpen = true;
    },
    convertQuestionSingle() {
      if (this.type_question == defaultConstant.SINGLE_CHOICE) {
        this.question.questions = this.question.questions.map((item) => {
          if (item.value){
            return item
          }
          else{
            return {
              value: item.trim(),
            };
          }
        });
      }
    },
    addAnswerSingle() {
      this.question.questions.push({ value: "" });
    },
    deleteAnswerSingle(index, value) {
      this.question.questions.splice(index, 1);
      if (this.question.answers[0] == value) {
        this.question.answers = [];
      }
    },

    save() {
      if (this.type_question == defaultConstant.SINGLE_CHOICE) {
        this.formatQuestionSingle();
      }
      if (!this.questionUpdate && this.questionUpdate != 0) {
        this.questions.push(this.question);
      }
    },
    close() {
      if (this.type_question == defaultConstant.SINGLE_CHOICE) {
        this.formatQuestionSingle();
      }
      this.questionUpdate = null;
    },
    resetQuestion() {
      this.question = JSON.parse(JSON.stringify(defaultObject));
      this.question.type = this.type_question;
    },
    formatQuestionSingle() {
      this.question.questions = this.question.questions.map((item) => {
        return item.value.trim();
      });
    },
  },
};
</script>
<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
}
.col-1 {
  padding-right: 0;
  padding-left: 0;
}
</style>
