<template>
  <component
    :is="componentName"
    :component-data="componentData"
    :key="index"
    @updated="$emit('updated')"
  />
</template>

<script>
import Unknown from './TypeUnknown.vue'
import Speaking from './TypeSpeaking/DeepCatchUp.vue'
import Listening from "./TypeListening/TopicCard.vue";
import Vocab from './TypeVocab.vue'
import PracticeScenario from './TypePracticeScenario/Component.vue'
export default {
  name: "StageTypeDetail",
  components: {
    "stage-type-1": Speaking,
    "stage-type-2": Listening,
    "stage-type-3": Vocab,
    "stage-type-4": PracticeScenario,
    "unknown-type": Unknown,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      index: 1
    }
  },
  computed: {
    componentName() {
      let name = `stage-type-` + this.componentData.type;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(name) ? name : "unknown-type";
    },
  },
  watch: {
    'componentData.content'() {
      this.index++
    }
  },
};
</script>
