var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('section',{staticClass:"mb-2"},[_c('b-card',{staticClass:"my-1"},[_c('form-generator',{attrs:{"model":_vm.item,"schema":_vm.item_form_schema}})],1),_c('h3',{staticClass:"h2 mt-3"},[_vm._v("Useful Language")]),_c('dynamic-zone',{ref:"useful_vocab",attrs:{"item-collapse":"last","items":_vm.item.vocabs,"computeTitleFn":_vm.usefulVocabTitle,"default-data":{
      title: {
        type: 'translatable-text',
        value: null,
        translations: [],
      },
      words: [],
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var vocab_item = ref.item;
return [_c('form-generator',{attrs:{"model":vocab_item,"schema":[{
          cols: 12, fields: [
            { label: 'Title', field: 'title', input_type: 'ui-component' }
          ],
        }]}}),_c('word-list',_vm._b({staticClass:"word_list",attrs:{"word-ids":vocab_item.words,"hideWordType":true,"hideWordImg":true}},'word-list',_vm.$attrs,false))]}}],null,false,2504586971)}),_c('h3',{staticClass:"h2 mt-3"},[_vm._v("AI config")]),_c('b-card',{staticClass:"mt-1"},[_c('form-generator',{attrs:{"model":_vm.item.openai_config,"schema":_vm.ai_config_schema}}),(_vm.item.openai_config.auto_chat)?_c('div',{staticClass:"border p-1"},[_c('h4',{staticClass:"h4"},[_vm._v("Default messages")]),_c('dynamic-zone',{ref:"default_messages",attrs:{"item-collapse":"last","items":_vm.item.openai_default_messages,"title_field":"content","default-data":{
          content: '',
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var message_item = ref.item;
return [_c('form-generator',{attrs:{"model":message_item,"schema":[{
              cols: 12, fields: [
                { label: 'Content', field: 'content', input_type: 'textarea' }
              ],
            }]}})]}}],null,false,2316382830)})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }